<template>

  <NavBar />

  <header class="bg-slate-800 w-full">

    <Slideshow />

    <div
      class="relative max-w-4xl mx-auto text-center bt-1 px-12 py-6 bg-slate-800  font-semibold text-lime-100 text-xl">
      Areál je otevřen od dubna do září, pro rezervaci volejte <a href="tel:+420776 488 764">776 488 764</a>
    </div>

  </header>


  <main class="p-4 text-slate-800 prose lg:prose-lg container mx-auto">


    <section>
      <!-- <h1 class="text-5xl font-normal leading-normal mt-0 mb-2 text-slate-700">
        Golf i Fotbalgolf
      </h1> -->
      <p>Přijďte si se svými kamarády zahrát fotbalgolf nebo si potrénujte golfový odpal včetně hry na greenu.</p>
      <p>Čeká Vás odpoledne plné zábavy a odpočinku.</p>
      <p>Naše obsluha Vám zajistí i malé občerstvení.</p>

    </section>

    <section>
      <h1 id="golf" class="scroll-mt-6">Golf</h1>
      <div class="sm:flex flex-row-reverse">
        <p class="mt-2">Golfový areál v Doksech je svou náročností určen převážně začátečníkům, kteří si zde mohou
          vyzkoušet jak odpalování tak puttování.</p>
        <div>
          <img class="sm:w-5/6"
            src="https://ucarecdn.com/81c2739b-455c-484c-bad8-2a4ed4599ff4/-/preview/-/quality/smart/"
            alt="Golfový areál">
        </div>
      </div>

    </section>

    <section>
      <h1 id="fotbalgolf" class="scroll-mt-6">FotbalGolf</h1>
      <div class="sm:flex ">
        <div>
          <p>
            Nově jsme otevřeli devítijamkové hřiště, které je vhodné pro všechny typu hráčů.
          </p>
          <p>Hru si užije jak úplný začátečník tak ostřílený fotbalgolfista.</p>
          <p>Čím více hráčů, tím více zábavy.</p>
          <p>Fotbalové míče půjčíme zdarma.</p>
        </div>
        <div>
          <img class="sm:w-5/6" src=" https://ucarecdn.com/776f5ecd-3242-4eaf-acd1-043f1849e98a/" alt="Golfový areál">
        </div>
      </div>
    </section>

    <section>
      <h1>Ceník</h1>
      <p>
        <!-- Naše ceny Vás překvapí, jde nám o zábavu. Poskytujeme skupinové slevy. -->
        Pro rezervaci volejte <a href="tel:+420776 488 764">776 488 764</a>
      <table>
        <tr>
          <td>
            <b>Golf</b>
            <ul>
              <li>Vstup - ZDARMA</li>
              <li>Zapůjčení hole 50,- Kč/ks</li>
              <li>Míče na driving range 1,- Kč/ks </li>
            </ul>
            <b class="block mt-2">FotbalGolf</b>
            <ul>
              <li>Dospělí: 9 jamek - 100 Kč, 18 jamek - 150 Kč</li>
              <li>Děti: 9 jamek - 50 Kč, 18 jamek - 100 Kč</li>
            </ul>
            <b class="block mt-2">Firemní akce</b>
            <ul>
              <li>Cenu Vám ušijeme na míru.</li>
              <li>Pro detaily volejte <a href="tel:+420776 488 764">776 488 764</a></li>
            </ul>
          </td>
        </tr>
      </table>
      </p>
    </section>

    <section>
      <h1>O nás</h1>
      <p>Jsme golfoví nadšenci, kterým učaroval tento nádherný sport. Založili jsme proto občanské sdružení - golfový
        klub, jehož cílem je rozvoj golfové hry pro nejširší veřejnost. Náš areál je určen především pro trénink krátké
        hry, kterou si můžete vyzkoušet na našem chipping a putting greenu a driving range. U nás si zahrajete golf i
        bez Zelené karty.</p>
      <p>Všechny aktuální novinky najdete na našem <a
          href="https://www.facebook.com/pages/Golf-Club-Doksy/179444881091">Facebooku</a> nebo Instagramu.</p>
    </section>

    <section>
      <h1 id="kontakt" class="scroll-mt-10">Kde nás najdete</h1>

      <div class="h-64 w-full mt-2">
        <h4>Mapa</h4>
        <iframe style="border:none" src="https://en.frame.mapy.cz/s/hamegahodu" height="90%" width="100%"
          frameborder="0"></iframe>
      </div>

      <div class="sm:grid sm:grid-cols-2 sm:gap-2 lg:grid-cols-4 lg:gap-4">
        <div>
          <h4>Adresa</h4>
          <p>
            Poslův Mlýn - areál Termit<br>
            472 01 Doksy<br>
            Česká Republika
          </p>
        </div>

        <div>
          <h4>Kontakty</h4>
          <p>
            Martin Kužel<br>
            <a href="tel:++420776488764">+420 776 488 764</a><br>
            <a href="mailto:info@golfdoksy.cz">info@golfdoksy.cz</a><br>
            www.golfdoksy.cz
          </p>
        </div>

        <div>
          <h4>Sociální sítě</h4>
          <p>
            <a class="block" href="https://www.facebook.com/pages/Golf-Club-Doksy/179444881091">Facebook</a>
            <a class="block" href="https://twitter.com/GolfDoksy">Twitter</a>
            <a class="block" href="https://twitter.com/GolfDoksy">Instagram</a>
          </p>
        </div>

        <div>
          <h4>Lidé</h4>
          <p>
            <a class="block" href="mailto:martin.kuzel@golfdoksy.cz">Martin Kužel</a>
            <a class="block" href="mailto:zdenek.krenicky@golfdoksy.cz">Zdeněk Krenický</a>
            <a class="block" href="mailto:jiri.pokorny@golfdoksy.cz">Jiří Pokorný</a>
            <a class="block" href="mailto:pavel.bernardin@golfdoksy.cz">Pavel Bernardin</a>
            <a class="block" href="mailto:robert.smol@golfdoksy.cz">Robert Šmol</a>
          </p>
        </div>
      </div>
    </section>

  </main>

  <footer class="relative mt-12">
    <img src="/images/footer.png" alt="Footer" class="absolute bottom-0">
  </footer>


  <!-- 
  <div id="root" class="text-slate-900 antialiased">
    <div class="bg-gray-200 min-h-screen p-8 flex items-center justify-center">
      <PropertyCard :property="property"/>
    </div>

  </div> -->

</template>

<script>
// import PropertyCard from './components/PropertyCard.vue'
import NavBar from './components/NavBar.vue'
import Slideshow from './components/Slideshow.vue'

export default {
  name: 'App',
  components: {
    // PropertyCard,
    NavBar,
    Slideshow
  },
  data: () => ({
    property: {
      imageURL: "/images/propertyone.jpeg",
      imageAlt: "Rear view with modern architecture",
      beds: 3,
      baths: 2,
      title: "Modern home in city center",
      priceInCents: 190000,
      formatedPrice: "$1,900.00",
      reviewCount: 34,
      rating: 4,
    }
  })  
}

</script>

<style>
/* #app { */
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px; */
/* } */

</style>
